<script>
import ui from '/~/core/ui'
import { getMenuActiveItem } from '/~/utils/menu'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useUI } from '/~/composables/ui'
import MarketplaceMenu from '/~/layouts/main/components/marketplace-menu.vue'
import LayoutRounded from '/~/layouts/rounded/layout-rounded.vue'
import LayoutView from '/~/templates/bill-payments/layouts/view/layout-view.vue'

export default {
  name: 'rewards-desktop',
  components: {
    LayoutView,
    MarketplaceMenu,
    LayoutRounded,
    BaseIcon,
  },
  setup() {
    const { isLeftMenuAppearanceHidden } = useCms()
    const { leftMenu, uiReady } = useUI()

    return {
      isLeftMenuAppearanceHidden,
      leftMenu,
      uiReady,
      ui,
    }
  },
  computed: {
    activeTopMenuItem() {
      if (!this.uiReady || !this.leftMenu) return

      return getMenuActiveItem(this.$route, this.leftMenu)
    },
    description() {
      return this.activeTopMenuItem?.description
    },
    title() {
      return this.activeTopMenuItem?.label
    },
    menu() {
      return this.activeTopMenuItem?.children ?? []
    },
  },
}
</script>

<template>
  <layout-view :heading="title" :menu="menu">
    <template #description>
      <div v-if="description" v-html="description" />
    </template>

    <layout-rounded>
      <template v-if="!ui.mobile" #header>
        <marketplace-menu
          v-if="!isLeftMenuAppearanceHidden"
          class="mx-auto max-w-7xl px-7"
        />

        <portal-target name="rewards-header" class="mx-auto max-w-7xl px-7" />
      </template>

      <router-view class="px-7" />

      <template #back-to-top="{ showBackToTop, scrollTop }">
        <button
          v-if="showBackToTop"
          class="fixed bottom-11 right-2.5 z-10 flex cursor-pointer items-center rounded bg-primary px-[15px] py-2.5 text-sm font-bold text-white hover:bg-primary-darken hover:opacity-90 hover:shadow sm:right-10 sm:py-[5px]"
          @click="scrollTop"
        >
          <base-icon svg="plain/arrow-up.v2" :size="16" />
          <span>Back to top</span>
        </button>
      </template>
    </layout-rounded>
  </layout-view>
</template>
