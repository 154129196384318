<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useCms } from '/~/composables/cms/use-cms'
import MarketplaceMenu from '/~/layouts/main/components/marketplace-menu.vue'

export default {
  name: 'rewards-mobile',
  components: {
    BaseAsidePage,
    MarketplaceMenu,
  },
  setup() {
    const { isLeftMenuAppearanceHidden } = useCms()

    return {
      isLeftMenuAppearanceHidden,
    }
  },
  data() {
    return {
      showSearch: false,
      title: 'Rewards',
    }
  },
  computed: {
    isCatalogRoute() {
      const catalogs = [
        'giftcards',
        'estore',
        'dining',
        'cinema',
        'community-hub',
        'competitions',
      ]

      return catalogs.includes(this.$route.name)
    },
    back() {
      if (this.isCatalogRoute) {
        return { name: 'home' }
      } else {
        return this.$route.meta.back || null
      }
    },
  },
  watch: {
    '$route.name'(route) {
      if (this.title !== route && this.isCatalogRoute) {
        this.title = 'Rewards'
      }
    },
  },
}
</script>

<template>
  <base-aside-page :title="title" :back="back" no-padding shadow>
    <!-- NOTE: wrap in div because we cannot pass multiple components -->
    <div>
      <marketplace-menu v-if="!isLeftMenuAppearanceHidden" />
      <div>
        <!-- NOTE: wrap in div because it will insert new route component above marketplace menu -->
        <router-view @set-title="title = $event" />
      </div>
    </div>
  </base-aside-page>
</template>
